<template>
  <v-card
    class="function-editor"
    scrollable
  >
    <v-card-title class="headline">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <p>This editor should be used to input a simple mathematic formula.</p>
      <strong>Inputs </strong><span>(Click to add to formula)</span>
      <v-chip-group
        active-class="primary--text"
      >
        <v-chip
          v-for="(item, index) in variables"
          :key="(item, index)"
          color="shamrock"
          outlined
          @click="addVariable(item.ref)"
        >
          <strong>{{ item.ref }}</strong>
          <span class="pl-1">({{ item.label }})</span>
        </v-chip>
      </v-chip-group>
      <v-textarea
        ref="formula-text-field"
        filled
        class="mt-3"
        :class="{'mb-3': error}"
        :value="formula"
        :prefix="`${output} = `"
        :error-messages="error"
        @change="handleInput($event)"
        @input="handleInput($event)"
      />
      <FormulaHelpInfo
        :key="2"
        :variables="{ 'Available variables:': Object.fromEntries(variables.map((x) => [x.ref, x.label])) }"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        class="mr-4"
        color="shamrock"
        style="transform: translateY(-10px);"
        @click="saveFormula()"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { evaluate } from 'mathjs'
import FormulaHelpInfo from '@/components/atoms/FormulaHelpInfo.vue'

export default {
  name: 'FormulaEditor',
  components: { FormulaHelpInfo },
  props: {
    inputs: {
      type: Array,
      default: () => []
    },
    output: {
      type: String,
      default: ''
    },
    initialFormula: {
      type: String,
      default: ''
    },
    additionalVariables: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Formula editor'
    },
    visible: {
      type: Boolean
    }
  },
  data () {
    return {
      formula: '',
      error: null
    }
  },
  computed: {
    variables () {
      return [
        ...this.inputs,
        ...this.additionalVariables
      ]
    }
  },
  watch: {
    visible () {
      // this.formula = ''
    }
  },
  mounted () {
    this.formula = this.initialFormula
    setTimeout(() => {
      this.$refs['formula-text-field'].focus()
    }, 300)
  },
  methods: {
    addVariable (name) {
      if (this.formula.length && !this.formula.endsWith(' ')) {
        this.formula += ' '
      }
      this.formula += name
      this.$refs['formula-text-field'].focus()
    },
    handleInput (e) {
      this.formula = e
      this.validate()
    },
    validate () {
      try {
        // evaluate formula to see if it's valid - don't actually evaluate variables yet, just pass all as 1
        const vars = ['$density', ...this.inputs.map(x => x.ref)]
        const scope = Object.fromEntries(vars.map(x => [x, 1]))
        evaluate(this.formula, scope)
        this.error = []
      } catch (err) {
        switch (err.message) {
          default:
            this.error = 'Invalid formula - ' + err.message
            break
        }
      }
    },
    saveFormula () {
      this.$emit('save', this.formula)
    }
  }
}
</script>

<style>

</style>
