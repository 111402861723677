<template>
  <div>
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </div>
</template>

<script>

export default {
  name: 'LineChart',
  props: {
    chartOptions: {
      type: Object,
      default: () => ({
        series: [
          {
            data: [[1, 1], [2, 10], [10, 10]]
          }
        ]
      })
    },
    editText: {
      type: String,
      default: 'Edit'
    },
    hideEdit: {
      type: Boolean,
      default: false
    }
  }
}

</script>
