import { getAxios } from '@/main'

export default async function (options: {x: number[], x2: number, y: number[], spacing: number, degree: number, zero: number | undefined | null}) {
  const ret = await getAxios().post('/utility/polynomial', {
    params: options
  })
  if (!ret.data.error) {
    return ret.data?.res
  } else {
    return []
  }
}
