<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="d-flex align-center">
          <v-icon class="pr-4">
            mdi-help-circle
          </v-icon>
          Help
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>The editor combines variables with standard math operators allowing you to write simple to complex formulas.</p>
        <p>
          <a
            href="https://mathjs.org/docs/expressions/syntax.html#operators"
            target="_blank"
            class="shamrock--text"
          >
            Click here for a list of operators
          </a>
        </p>
        <p>Variables are written with a dollar sign ($).</p>
        <p>For sequestration values, simply use a minus sign to negate the entered emissions value.</p>
        <p
          v-for="(subVariables, type) in variables"
          :key="type"
          class="subtitle-2 mb-1"
        >
          {{ type }}
          <ul style="list-style: none; padding-left: 1em; margin-top: 5px;">
            <li
              v-for="(value, key) in subVariables"
              :key="key"
            >
              <strong>{{ key }}</strong>{{ value && ` - ${value}` }}
            </li>
          </ul>
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'FormulaHelpInfo',
  props: {
    variables: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
