// this function returns an array of suggested layouts based on the symbol by which an element's co2 is measured

export default function getLayoutSuggestions (type) {
  switch (type) {
    case 'count':
      return [
        [
          {
            label: 'Unit',
            type: 'count'
          }
        ],
        [
          {
            label: 'Area',
            type: 'area'
          }
        ]
      ]
    case 'length':
      return [
        [
          {
            label: 'Length',
            type: 'length'
          }
        ]
      ]
    case 'area':
      return [
        [
          {
            label: 'Length',
            type: 'length'
          },
          {
            label: 'Width',
            type: 'length'
          }
        ],
        [
          {
            label: 'Area',
            type: 'area'
          }
        ]
      ]
    case 'volume':
      return [
        [
          {
            label: 'Length',
            type: 'length'
          },
          {
            label: 'Width',
            type: 'length'
          },
          {
            label: 'Height',
            type: 'length'
          }
        ],
        [
          {
            label: 'Area',
            type: 'area'
          },
          {
            label: 'Depth',
            type: 'length'
          }
        ],
        [
          {
            label: 'Volume',
            type: 'volume'
          }
        ]
      ]
    case 'weight':
      return [
        [
          {
            label: 'Length',
            type: 'length'
          },
          {
            label: 'Width',
            type: 'length'
          },
          {
            label: 'Height',
            type: 'length'
          }
        ],
        [
          {
            label: 'Area',
            type: 'area'
          },
          {
            label: 'Depth',
            type: 'length'
          }
        ],
        [
          {
            label: 'Volume',
            type: 'volume'
          }
        ],
        [
          {
            label: 'Weight',
            type: 'weight'
          }
        ]
      ]
    default:
      return []
  }
}
