<template>
  <div
    class="px-3 py-1 formula-preview"
    :class="{
      'invalid': invalid,
      'empty': !formula.replace(/ /g, '').length
    }"
  >
    <span
      v-if="invalid"
      class="error--text d-flex align-center"
    >
      <v-icon
        small
        class="pr-2"
        color="error"
      >mdi-alert-circle</v-icon>
      Invalid dimensions for {{ co2MeasuredPer }}. Consider a dimension with custom units.
    </span>
    <span
      v-else-if="!formula.replace(/ /g, '').length"
      class="warning--text d-flex align-center"
    >
      <v-icon
        small
        class="pr-2"
        color="warning"
      >mdi-alert-circle</v-icon>
      Custom formula required.
    </span>
    <span v-else>
      <strong v-if="co2Unit">{{ co2Unit.type }}</strong>
      <span v-if="co2Unit"> {{ co2MeasuredPer && '(' + co2MeasuredPer + ')' }}</span>
      <span v-if="!co2Unit">{{ co2MeasuredPer }}</span>
      <span> = </span>
      <span
        v-for="(str, index) of niceFormula"
        :key="index"
        class="py-1 code"
        :style="{
          fontWeight: index % 2 ? '600' : '400'
        }"
      >
        {{ str }}
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters as mapGlobalGetters } from 'vuex'

export default {
  name: 'FormulaPreview',
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    formula: {
      type: String,
      default: ''
    },
    variables: {
      type: Array,
      required: true
    },
    co2MeasuredPer: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGlobalGetters(['unitMappings']),

    co2Unit () {
      return this.unitMappings.find(x => x.symbol === this.co2MeasuredPer)
    },
    niceFormula () {
      let formula = this.formula + ' '
      for (const item of this.variables) {
        const safed = item.ref.replace(/\$/g, '\\$') + ' '
        const rep = new RegExp(safed, 'g')
        formula = formula.replace(rep, `<[${item.label}]> `)
      }
      return formula.substr(0, formula.length - 1).split(/<|>/)
    }
  }
}
</script>

<style lang="scss" scoped>
.formula-preview {
  border-bottom: 2px solid var(--v-shamrock-base);
  &.invalid {
    border-bottom: 2px solid var(--v-error-base) !important;
  }
  &.empty {
    border-bottom: 2px solid var(--v-warning-base);
  }
  .code {
    font-family: Monospace; letter-spacing: 0px; background-color: var(--v-secondary-base); padding-left: 3px; padding-right: 3px;
  }
}

</style>
